<template>
  <div>
    <h2 class="th-title">Transaksi</h2>
    <a-drawer
      width="700"
      placement="right"
      :visible="visibleTransactionDetailModal"
      title="Detail Transaksi"
      @close="hideTransactionDetailModal"
    >
      <div :style="{ display: 'flex', flexDirection: 'column', alignItems: 'center', }" >
        <img v-if="dataDetail.title == 'Receive Gift'" src="@/assets/img/receive_gift.png" :alt="dataDetail.title" :placeholder="dataDetail.title" :width="120" />
        <img v-else-if="dataDetail.title == 'Send Gift'" src="@/assets/img/send_gift.png" :alt="dataDetail.title" :placeholder="dataDetail.title" :width="120" />
        <img v-else-if="dataDetail.title == 'Purchased Content'" src="@/assets/img/purchased_content.png" :alt="dataDetail.title" :placeholder="dataDetail.title" :width="120" />
        <img v-else-if="dataDetail.title == 'Buying Content'" src="@/assets/img/buy_content.png" :alt="dataDetail.title" :placeholder="dataDetail.title" :width="120" />
        <img v-else-if="dataDetail.title == 'Get Point'" src="@/assets/img/get_point.png" :alt="dataDetail.title" :placeholder="dataDetail.title" :width="120" />
        <img v-else-if="dataDetail.title == 'Redeem Reward'" src="@/assets/img/redeem_reward.png" :alt="dataDetail.title" :placeholder="dataDetail.title" :width="120" />
        <img v-else src="@/assets/img/top_up.png" :alt="dataDetail.title" :placeholder="dataDetail.title" :width="120" />
        <h5 :style="{ textAlign: 'center', marginBlock: '20px' }" >{{dataDetail.title}}</h5>
      </div>
      <a-descriptions bordered :column="2">
        <a-descriptions-item :span="2" label="Status">
          <a-badge :status="(dataDetail.status == 'success') ? 'success' : (dataDetail.status == 'on_progress') ? 'processing' : 'error'" :text="capitalizeFirstLetter(dataDetail.status)" />
        </a-descriptions-item>
        <a-descriptions-item :span="2" label="Id">{{dataDetail._id}}</a-descriptions-item>
        <a-descriptions-item :span="2" label="Keterangan">
          {{dataDetail.description ? dataDetail.description.includes('Your') || dataDetail.description.includes('your') ? dataDetail.description.replace(/your/gi, `@${dataDetail.myData?.username}`) :dataDetail.description.includes('You') || dataDetail.description.includes('you') ? dataDetail.description.replace(/you/gi, `@${dataDetail.myData?.username}`) : dataDetail.description : null}}
          </a-descriptions-item>
        <a-descriptions-item :span="2" label="Metode Pembaran">
          <div :style="{ display: 'flex', }">
            <div v-if="dataDetail?.paymentChannelIcon" :style="{ marginRight: '10px', }">
              <a-image
                :src="`${URL}${dataDetail?.paymentChannelIcon}`"
                :alt="dataDetail?.paymentChannelCode || 'xenditIcon'"
                :width="20"
              />
            </div>
            {{dataDetail?.paymentType == 'currency' ? dataDetail?.paymentChannelCode ? `${dataDetail?.paymentChannelCategory} - ${dataDetail?.paymentChannelCode}` : 'xendit' : dataDetail?.paymentType}}
          </div>
        </a-descriptions-item>
        <a-descriptions-item label="Harga Kotor">
          {{`${dataDetail.paymentType == 'currency' ? `IDR ${commaNumber(dataDetail.basePriceInCurrency)}` : `${commaNumber(dataDetail.basePriceInCoin)} coins`}`}}
        </a-descriptions-item>
        <a-descriptions-item label="List Biaya">
          <a-list :data-source="dataDetail.fees">
            <template #renderItem="{ item }">
              <a-list-item>{{item.type}}: {{commaNumber(item.value)}}</a-list-item>
            </template>
          </a-list>
        </a-descriptions-item>
        <a-descriptions-item label="Total Harga">{{`${dataDetail.paymentType == 'currency' ? 'IDR ' : ''}${commaNumber(dataDetail.totalPrice)}${dataDetail.paymentType !== 'currency' ? ' coins' : ''}`}}</a-descriptions-item>
        <a-descriptions-item label="Tanggal Transaksi">{{dataDetail.transactionAt}}</a-descriptions-item>
        <a-descriptions-item label="Tanggal Pembayaran">{{dataDetail.paidAt}}</a-descriptions-item>
        <a-descriptions-item label="Tanggal Ekspirasi">{{dataDetail.expiredAt}}</a-descriptions-item>
      </a-descriptions>
    </a-drawer>
    <a-card>
      <div class="d-flex justify-content-between mb-3">
        <a-input
          style="width: 220px"
          :value="search"
          placeholder="Cari dengan Id"
          @change="handleSearch"
        >
          <template #prefix>
            <span>
              <search-outlined style="color: rgba(0, 0, 0, 0.25)" />
            </span>
          </template>
        </a-input>
        <a-select class="ml-2" style="width: 200px;" :value="paymentType" @change="handleSelectPaymentType" >
          <a-select-option value="all">
            All
          </a-select-option>
          <a-select-option value="coins">
            Coin
          </a-select-option>
          <a-select-option value="currency">
            Xendit
          </a-select-option>
        </a-select>
      </div>
      <div>
        <a-table :class="$style.table" :columns="columns" :data-source="dataTable" :loading="loadingTable" bordered :pagination="globalPagination" @change="onTableChange" >
          <template #key="{ text, record }">
            <a-button type="link" @click="openWithdrawDetail(record)" >{{text}}</a-button>
          </template>
          <template #customerUsername="{ text, record }">
            <a-list>
              <div v-if="record.transactionTitle == 'Receive Gift'">
                <a-list-item>
                  Penerima: <router-link class="text-primary kit__utils__link" :to="{ name: 'Detail Akun', params: { id: record.customerId } }">{{ text }}</router-link>
                </a-list-item>
                <a-list-item>
                  Pemberi: <router-link class="text-primary kit__utils__link" :to="{ name: 'Detail Akun', params: { id: record.involvedUserId } }">{{ record.involvedUserUsername }}</router-link>
                </a-list-item>
              </div>
              <div v-else-if="record.transactionTitle == 'Send Gift'">
                <a-list-item>
                  Pemberi: <router-link class="text-primary kit__utils__link" :to="{ name: 'Detail Akun', params: { id: record.customerId } }">{{ text }}</router-link>
                </a-list-item>
                <a-list-item>
                  Penerima: <router-link class="text-primary kit__utils__link" :to="{ name: 'Detail Akun', params: { id: record.involvedUserId } }">{{ record.involvedUserUsername }}</router-link>
                </a-list-item>
              </div>
              <div v-else-if="record.transactionTitle == 'Purchased Content'">
                <a-list-item>
                  Penjual: <router-link class="text-primary kit__utils__link" :to="{ name: 'Detail Akun', params: { id: record.customerId } }">{{ text }}</router-link>
                </a-list-item>
                <a-list-item>
                  Pembeli: <router-link class="text-primary kit__utils__link" :to="{ name: 'Detail Akun', params: { id: record.involvedUserId } }">{{ record.involvedUserUsername }}</router-link>
                </a-list-item>
              </div>
              <div v-else-if="record.transactionTitle == 'Buying Content'">
                <a-list-item>
                  Pembeli: <router-link class="text-primary kit__utils__link" :to="{ name: 'Detail Akun', params: { id: record.customerId } }">{{ text }}</router-link>
                </a-list-item>
                <a-list-item>
                  Penjual: <router-link class="text-primary kit__utils__link" :to="{ name: 'Detail Akun', params: { id: record.involvedUserId } }">{{ record.involvedUserUsername }}</router-link>
                </a-list-item>
              </div>
              <div v-else>
                <a-list-item>
                  Pembeli: <router-link class="text-primary kit__utils__link" :to="{ name: 'Detail Akun', params: { id: record.customerId } }">{{ text }}</router-link>
                </a-list-item>
              </div>
            </a-list>
          </template>
          <template #status="{ text }">
            <a-tag :color="text == 'failed' ? 'red' : text == 'success' ? 'green' : 'blue'" >{{text}}</a-tag>
          </template>
          <template #totalPrice="{ text, record }">
            {{ (record.paymentType == 'currency' ? 'IDR ' : '') + commaNumber(text, '.') + (record.paymentType == 'coins' ? ' coins' : '')}}
          </template>
        </a-table>
      </div>
    </a-card>
  </div>
</template>

<script>
import moment from 'moment';
import priceFormat from '@/helpers/rupiahPrice';
import { commaNumber } from '@/helpers/numberHelper';
import { onMounted, reactive, ref, toRaw } from 'vue';
import { useStore } from 'vuex';
import { SearchOutlined , CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined } from '@ant-design/icons-vue';
const URL = process.env.VUE_APP_BACKEND_URL

const columns = [
  {
    title: 'No',
    dataIndex: 'rowNumber',
    key: 'rowNumber',
    slots: { customRender: 'rowNumber' },
    align: 'center',
    fixed: 'left',
  },
  {
    title: 'Id',
    dataIndex: 'key',
    key: 'key',
    slots: { customRender: 'key' },
    align: 'center',
    fixed: 'left',
    sorter: (a, b) => a.key - b.key,
  },
  {
    title: 'Xendit Id',
    dataIndex: 'xenditId',
    key: 'xenditId',
    slots: { customRender: 'xenditId' },
    align: 'center',
  },
  {
    title: 'Judul Transaksi',
    dataIndex: 'transactionTitle',
    key: 'transactionTitle',
    slots: { customRender: 'transactionTitle' },
    align: 'center',
  },
  {
    title: 'Username',
    dataIndex: 'customerUsername',
    key: 'customerUsername',
    slots: { customRender: 'customerUsername' },
    align: 'center',
  },
  {
    title: 'Tipe Pembayaran',
    dataIndex: 'transactionPaymentMethod',
    key: 'transactionPaymentMethod',
    slots: { customRender: 'transactionPaymentMethod' },
    align: 'center',
  },
  {
    title: 'Total Pembayaran',
    dataIndex: 'totalPrice',
    key: 'totalPrice',
    slots: { customRender: 'totalPrice' },
    align: 'center',
  },
  {
    title: 'Tanggal Transaksi',
    dataIndex: 'createdAt',
    key: 'createdAt',
    slots: { customRender: 'createdAt' },
    align: 'center',
    defaultSortOrder: 'descend',
    sorter: (a, b) => moment(a.createdAt, 'DD MMMM YYYY, h:mm') - moment(b.createdAt, 'DD MMMM YYYY, h:mm'),
  },
  {
    title: 'Tanggal Pembayaran',
    dataIndex: 'paidAt',
    key: 'paidAt',
    slots: { customRender: 'paidAt' },
    align: 'center',
    sorter: (a, b) => moment(a.paidAt, 'DD MMMM YYYY, h:mm') - moment(b.paidAt, 'DD MMMM YYYY, h:mm'),
  },
  {
    title: 'Tanggal Ekspirasi',
    dataIndex: 'expiredAt',
    key: 'expiredAt',
    slots: { customRender: 'expiredAt' },
    align: 'center',
    sorter: (a, b) => moment(a.expiredAt, 'DD MMMM YYYY, h:mm') - moment(b.expiredAt, 'DD MMMM YYYY, h:mm'),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    slots: { customRender: 'status' },
    align: 'center',
    fixed: 'right',
  },
];

export default {
  components: {
    SearchOutlined,
    // CheckCircleOutlined,
    // ClockCircleOutlined,
    // CloseCircleOutlined,
    // PlusOutlined,
    // CheckOutlined,
    // CloseOutlined,
  },
  setup() {
    const store = useStore()
    let dataTable = ref([])
    let globalPagination = ref({ current: 1, pageSize: 10 })
    let paymentType = ref('all')
    let loadingTable = ref(false)
    let search = ref('')
    let sortBy = ref('createdAt')
    let order = ref('desc')
    let visibleTransactionDetailModal = ref(false)
    const dataDetail = ref({})

    onMounted(() => {
      fetchTransaction()
    })

    const capitalizeFirstLetter = (string) => {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const handleSearch = (e) => {
      // console.log('handleSearch', e.target.value);
      search.value = e.target.value;
      globalPagination.value = { ...globalPagination.value, current: 1 };
      fetchTransaction(search.value);
    }

    const handleSelectPaymentType = (val) => {
      // console.log('handleSelectPaymentType val', val)
      paymentType.value = val;
      globalPagination.value = { ...globalPagination.value, current: 1 };
      fetchTransaction()
    }

    const fetchTransaction = async (search) => {
      const API_getAllTransaction = await store.dispatch('transaction/FETCH_ALL_TRANSACTION', { search, paymentType: paymentType.value, page: globalPagination.value.current, limit: globalPagination.value.pageSize, sortBy: sortBy.value, order: order.value });
      
      if (API_getAllTransaction) {
        const { data, totalAllData } = API_getAllTransaction;
        // console.log('data fetchTransaction =>', data)
        globalPagination.value = { ...globalPagination.value, total: totalAllData }
        dataTable.value = data
      }
    }

    const onTableChange = async(pagination, filters, sorter) => {
      // console.log('pagination, filters, sorter', pagination, filters, sorter)
      globalPagination.value.current = pagination.current;
      if (sorter) {
        if (sorter.columnKey == 'key') sortBy.value = '_id';
        else if (sorter.columnKey == 'customerUsername') sortBy.value = '$customer.username';
        else sortBy.value = sorter.columnKey;
        order.value = sorter.order == 'ascend' ? 'asc' : 'desc';
      }
      fetchTransaction()
    }

    const openWithdrawDetail = async(record) => {
      const API_getTransactionDetail = await store.dispatch('transaction/FETCH_TRANSACTION_DETAIL', { id: record._id, idUser: record.customerId });
      // console.log('API_getTransactionDetail', API_getTransactionDetail)
      if (API_getTransactionDetail) dataDetail.value = API_getTransactionDetail;
      visibleTransactionDetailModal.value = true;
    }

    const hideTransactionDetailModal = () => {
      visibleTransactionDetailModal.value = false
    }

    return {
      URL,
      search,
      columns,
      dataTable,
      loadingTable,
      priceFormat,
      handleSearch,
      onTableChange,
      globalPagination,
      commaNumber,
      sortBy,
      order,
      openWithdrawDetail,
      visibleTransactionDetailModal,
      hideTransactionDetailModal,
      dataDetail,
      capitalizeFirstLetter,
      paymentType,
      handleSelectPaymentType,
    }
  },
}
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>